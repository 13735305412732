import React from 'react'
import QrSmall from '../../../../../images/product/business/qr-small.svg'
import QrBig from '../../../../../images/product/business/qr-big.svg'
import PhoneImg from '../../../../../images/product/business/phone.svg'
import { ContactWrap } from './atom'

export function Contact({ ...rest }) {
  return (
    <ContactWrap {...rest}>
      <img src={QrSmall} alt="" className="small-qr" loading="lazy" />
      <img src={PhoneImg} alt="" className="phone-img" loading="lazy" />
      <div className="phone">400 - 965 - 5018 转 3</div>
      <div className="big-qr-div">
        <div>添加企业微信</div>
        <img src={QrBig} alt="" className="big-qr" loading="lazy" />
        <div className="qr-desc">咨询产品服务详情</div>
      </div>
    </ContactWrap>
  )
}
