import React from 'react'
import BannerBtns from '../../../../../components/home/bannerBtns'
import { BusinessBannerWrap, BusinessBannerContent, Left } from './atom'
import { Path } from '@raylink/common/constants/path'
import { Contact } from '../contact'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export function BusinessBanner({ ...rest }) {
  const data = useStaticQuery(graphql`
    query {
      BannerRightImg: file(
        relativePath: { in: "product/business/bannerRight.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <BusinessBannerWrap {...rest}>
      <BusinessBannerContent>
        <Left>
          <div className="tag">RayLink远程控制·企业版</div>
          <h1 className="title">
            <span>企业远控</span>
            <span>一站式解决方案</span>
          </h1>
          <div className="desc">
            集快速部署、高效管理与顶级安全于一体，打造全面的远程办公平台，助力企业运作效率进入新阶段
          </div>
          <BannerBtns
            btnInfo={{
              first: {
                text: '申领企业版试用',
                href: Path.businessLogin,
              },
              second: {
                text: '立即咨询',
                href: Path.contact,
              },
            }}
            className="btn-div"
          />
          <Contact />
        </Left>
        <GatsbyImage
          image={data?.BannerRightImg?.childImageSharp.gatsbyImageData}
          alt=""
          className="banner-right"
          loading="lazy"
        />
      </BusinessBannerContent>
    </BusinessBannerWrap>
  )
}
