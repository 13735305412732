import React from 'react'
import { Layout, SEO } from '@raylink/common/components'
import {
  BusinessBanner,
  CompareTable,
  Deploy,
  Process,
  Scene,
  Plan,
  Accept,
  OneStop,
} from './component'
import { BusinessWrap } from './index.style'

export default function Business() {
  return (
    <Layout>
      <SEO
        title="RayLink企业版 - 企业级安全、高效、专业远程控制解决方案"
        keywords="公司远程控制,企业远程控制,远程控制软件,办公电脑远程,协同办公,远程办公,技术支持,it支持,远程高清设计,安全远程控制"
        description="RayLink企业版专为企业、团队提供轻量部署、高效管理于一体的安全远控解决方案。支持远程办公、远程高清设计、IT支持等场景，让企业成员在电脑、手机、平板等多设备之间轻松办公，让企业、团队运作更高效、更安全。"
        ogUrl="product/business/"
      />
      <BusinessWrap>
        <BusinessBanner />
        <Deploy />
        <Process />
        <Scene />
        <CompareTable />
        <Plan />
        <Accept />
        <OneStop />
      </BusinessWrap>
    </Layout>
  )
}
