import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import Bg from '../../../../../images/product/business/bg.webp'
import MobileBg from '../../../../../images/product/business/bgPhone.webp'
import { Media } from '@raylink/common/theme'

export const BusinessBannerWrap = styled.div`
  position: relative;
  width: 100%;
  height: 640px;
  min-width: 1200px;
  background: url(${Bg}) center center / auto 100% no-repeat;
  ${Media.desktopContact`
    background-size: 100% auto;
  `}
  ${Media.ipad`
    height: 576px;
    min-width: 928px;
  `}
  ${Media.phone`
    background: url(${MobileBg}) center center / auto 100% no-repeat;
    background-size: 100% 100%;
    height: 11.52rem;
    min-width: 100%;
  `}
`
export const BusinessBannerContent = styled(Warpper)`
  display: flex;
  justify-content: space-between;
  padding: 118px 35px 0 0;
  .banner-right {
    width: 644px;
  }
  ${Media.ipad`
    width: 928px;
    padding: 136px 0 0 0;
    .banner-right {
      width: 530px;
    }
  `}
  ${Media.phone`
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0.74rem;
    padding: 1.6rem 0.34rem 0;
    text-align: center;
    .banner-right {
      width: 5.92rem;
    }
  `}
`

export const Left = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  .tag {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    background: linear-gradient(
      90deg,
      #6a45ff -10.49%,
      #455fff 14.51%,
      #455fff 85.85%,
      #45b1ff 107.8%
    );
    background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .title {
    margin: 12px 0;
    font-size: 56px;
    color: #212121;
    line-height: 1.25;
    font-weight: 700;
    span {
      display: block;
    }
  }
  .desc {
    margin-bottom: 32px;
    font-size: 16px;
    color: #595959;
  }
  .btn-div {
    .banner-btn {
      padding: 0 40px !important;
    }
  }
  ${Media.ipad`
    width: 350px;
    margin-top: 14px;
    .tag {
      font-size: 16px;
    }
    .title {
      margin: 10px 0 8px;
      font-size: 48px;
    }
    .desc {
      margin-bottom: 24px;
    }
    .btn-div {
      .banner-btn {
        padding: 0 32px !important;
      }
    }
  `}
  ${Media.phone`
    width: 100%;
    align-items: center;
    margin-top: 0;
    .tag {
      font-size: 0.32rem;
    }
    .title {
      margin: 0.16rem 0 0.1rem;
      font-size: 0.6rem;
      line-height: 0.84rem;
      span {
        display: inline-block;
      }
    }
    .desc {
      margin-bottom: 0.64rem;
      font-size: 0.3rem;
      line-height: 0.48rem;
    }
    .btn-div {
      .banner-btn {
        padding: 0 0.64rem !important;
      }
    }
  `}
`
