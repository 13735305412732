import React from 'react'
import Arrow from '../../../../../images/product/business/process/arrow.svg'
import HoverImg from '../../../../../images/product/business/process/hover_bg.png'
import BannerBtns from '../../../../../components/home/bannerBtns'
import { Title } from '../../index.style'
import { lineList, processList } from '../../config'
import {
  ProcessWrap,
  ProcessContent,
  ProcessListWrap,
  ProcessItem,
  FeatList,
  LineWrap,
  LineLeft,
  BtnsWrap,
} from './atom'
import { Path } from '@raylink/common/constants/path'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export function Process() {
  const data = useStaticQuery(graphql`
    query {
      LineRightImg: file(
        relativePath: { in: "product/business/process/lineRight.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <ProcessWrap>
      <ProcessContent>
        <Title>全流程安全，尽在掌握</Title>
        <ProcessListWrap>
          {processList.map((item, index) => (
            <ProcessItem key={item.name} className={item.class}>
              <h3 className="name">{item.name}</h3>
              <FeatList>
                {item.featList.map(item => (
                  <div className="feat-item" key={item.title}>
                    <img
                      src={item.imgSrc}
                      alt=""
                      className="left-img"
                      loading="lazy"
                    />
                    <div className="right">
                      <div className="title">{item.title}</div>
                      <div className="desc">{item.desc}</div>
                    </div>
                  </div>
                ))}
              </FeatList>
              {index < 3 && (
                <img src={Arrow} alt="" className="arrow-img" loading="lazy" />
              )}
            </ProcessItem>
          ))}
        </ProcessListWrap>
        <Title>专享企业高速线路</Title>
        <LineWrap>
          <LineLeft>
            {lineList.map(item => (
              <div className="line-item" key={item.title}>
                <h3 className="l-title">{item.title}</h3>
                <div className="l-desc">{item.desc}</div>
                <img
                  src={HoverImg}
                  alt=""
                  className="hover-img"
                  loading="lazy"
                />
              </div>
            ))}
          </LineLeft>
          <GatsbyImage
            image={data?.LineRightImg?.childImageSharp.gatsbyImageData}
            alt=""
            className="line-img"
            loading="lazy"
          />
        </LineWrap>
        <BtnsWrap>
          <BannerBtns
            btnInfo={{
              first: {
                text: '申领企业版试用',
                href: Path.businessLogin,
              },
              second: {
                text: '立即咨询',
                href: Path.contact,
              },
            }}
            className="btn-div"
          />
        </BtnsWrap>
      </ProcessContent>
    </ProcessWrap>
  )
}
