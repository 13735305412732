import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const ContactWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 32px;
  .small-qr {
    width: 32px;
  }
  .phone-img {
    width: 20px;
    margin: 0 6px 0 16px;
  }
  .phone {
    font-family: 'D-DIN-PRO';
    font-size: 18px;
    color: #595959;
    font-weight: 500;
  }
  .big-qr-div {
    display: none;
    position: absolute;
    bottom: 40px;
    left: 0;
    padding: 16px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #212121;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(204, 204, 204, 0.3);
    .qr-desc {
      font-size: 12px;
      color: #878787;
    }
    .big-qr {
      width: 96px;
      margin: 6px 0;
    }
  }
  &:hover {
    .big-qr-div {
      display: block;
    }
  }
  ${Media.phone`
    display: none;
  `}
`
