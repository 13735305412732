import React from 'react'
import { Title } from '../../index.style'
import { deployList } from '../../config'
import { DeployWrap, DeployListWrap, DeployItem } from './atom'

export function Deploy() {
  return (
    <DeployWrap>
      <Title>轻松部署，高效管理</Title>
      <DeployListWrap>
        {deployList.map(item => (
          <DeployItem key={item.title}>
            <img src={item.imgSrc} alt="" loading="lazy" />
            <h3 className="title">{item.title}</h3>
            <div className="desc">{item.desc}</div>
          </DeployItem>
        ))}
      </DeployListWrap>
    </DeployWrap>
  )
}
