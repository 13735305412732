import { API, Axios } from '@raylink/common/utils'
import { BusinessProductId } from '../price-business/config'

export async function queryBusinessPrice() {
  const http = new Axios(API.businessAuth.host)
  const resp = await http.get(API.businessAuth.queryPricingList)
  if (resp?.code === 200 && resp?.data) {
    const officePrice = resp.data.find(
      ({ productId }) => productId === BusinessProductId.office
    ).price
    const industryPrice = resp.data.find(
      ({ productId }) => productId === BusinessProductId.industry
    ).price
    return {
      officePrice,
      industryPrice,
    }
  }
  return {}
}
