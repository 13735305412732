import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const DeployWrap = styled(Warpper)`
  ${Media.ipad`
    width: 928px;
  `}
  ${Media.phone`
    width: 100%;
  `}
`

export const DeployListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.phone`
    flex-direction: column;
    gap: 0.32rem;
  `}
`

export const DeployItem = styled.div`
  width: 364px;
  padding: 0 40px 16px;
  text-align: center;
  img {
    width: 106px;
    margin: auto;
  }
  .title {
    margin: 16px 0 6px;
    font-size: 24px;
    color: #212121;
    font-weight: 700;
  }
  .desc {
    font-size: 16px;
    color: #595959;
  }
  ${Media.ipad`
    width: 280px;
    padding: 0 0 16px;
    img {
      width: 94px;
    }
    .title {
      font-size: 22px;
    }
  `}
  ${Media.phone`
    width: 5.56rem;
    padding: 0;
    img {
      width: 1.76rem;
    }
    .title {
      margin: 0.24rem 0 0.12rem;
      font-size: 0.4rem;
    }
    .desc {
      font-size: 0.32rem;
    }
  `}
`
