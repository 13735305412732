import styled from 'styled-components'
import { Media, fillBtnStyle, lineBtnStyle } from '@raylink/common/theme'

export const BannerBtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .banner-btn {
    height: 44px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    padding: 0 40px;
  }
  ${Media.phone`
    gap: 0.36rem;
    .banner-btn {
      height: 0.66rem;
      border-radius: 0.09rem;
      font-size: 0.24rem;
      padding: 0 0.6rem;
    }
  `}
`

export const TryBtn = styled.a`
  ${fillBtnStyle}
`

export const MoreBtn = styled.a`
  ${lineBtnStyle}
  border: 1px solid #11C871;
  background: transparent;
  box-sizing: border-box;
  white-space: nowrap;
`
