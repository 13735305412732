import { Warpper } from '@raylink/common/components'
import styled from 'styled-components'
import Bg from '../../../../../images/product/business/process/bg.webp'
import BgIpad from '../../../../../images/product/business/process/bgIpad.webp'
import BgPhone from '../../../../../images/product/business/process/bgPhone.webp'
import { Media } from '@raylink/common/theme'

export const ProcessWrap = styled.div`
  background: linear-gradient(180deg, #fff 1.56%, rgba(255, 255, 255, 0) 26.12%),
    linear-gradient(
      90deg,
      rgba(213, 222, 255, 0.4) 0%,
      rgba(241, 249, 253, 0.5) 49.48%,
      rgba(213, 222, 255, 0.4) 100%
    );
`

export const ProcessContent = styled(Warpper)`
  ${Media.ipad`
    width: 928px;
  `}
  ${Media.phone`
    width: 100%;
  `}
`

export const ProcessListWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  ${Media.ipad`
    flex-wrap: wrap;
    row-gap: 24px;
    .two {
      .arrow-img {
        top: 264px;
        left: 345px;
        transform: rotate(90deg);
      }
    }
    .three {
      position: absolute;
      right: 0;
      bottom: 0;
      .arrow-img {
        top: 132px;
        left: -44px;
        transform: rotate(180deg);
      }
    }
  `}
  ${Media.phone`
    flex-direction: column;
    align-items: center;
    row-gap: 0.26rem;
    .three {
      position: relative;
    }
  `}
`

export const ProcessItem = styled.div`
  width: 278px;
  height: 329px;
  position: relative;
  background: url(${Bg}) no-repeat;
  background-size: 100% auto;
  .name {
    margin: 14px auto 53px;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .arrow-img {
    width: 58px;
    position: absolute;
    top: 179px;
    left: 273px;
    z-index: 2;
  }
  ${Media.ipad`
    width: 448px;
    height: 243px;
    background: url(${BgIpad}) no-repeat;
    .name {
      margin: 12px 0 8px 32px;
      text-align: left;
    }
    .arrow-img {
      width: 50px;
      top: 132px;
      left: 442px;
    }
  `}
  ${Media.phone`
    width: 5.56rem;
    height: 6.02rem;
    background: url(${BgPhone}) no-repeat;
    background-size: 100% 100%;
    .name {
      margin: 0.24rem auto 0.16rem;
      font-size: 0.4rem;
      text-align: center;
    }
    .arrow-img {
      display: none;
    }
  `}
`

export const FeatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 25px 40px;
  line-height: 1.5;
  .feat-item {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    .left-img {
      width: 36px;
    }
    .right {
      .title {
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: 500;
        color: #212121;
      }
      .desc {
        font-size: 16px;
        color: #595959;
      }
    }
  }
  ${Media.ipad`
    flex-direction: row;
    gap: 32px;
    padding: 35px 32px 30px;
    .feat-item {
      flex-direction: column;
      gap: 6px;
    }
  `}
  ${Media.phone`
    flex-direction: column;
    gap: 0.48rem;
    padding: 0.72rem 0.48rem 0.6rem;
    .feat-item {
      flex-direction: row;
      gap: 0.24rem;
      .left-img {
        width: 0.72rem;
      }
      .right {
        .title {
          margin-bottom: 0.12rem;
          font-size: 0.36rem;
        }
        .desc {
          font-size: 0.32rem;
        }
      }
    }
  `}
`

export const LineWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .line-img {
    width: 589px;
  }
  ${Media.ipad`
    .line-img {
      width: 483px;
    }
  `}
  ${Media.phone`
    flex-direction: column;
    .line-img {
      width: 100%;
      margin: 0.24rem 0 0.32rem;
    }
  `}
`

export const LineLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .line-item {
    position: relative;
    width: 432px;
    padding: 30px 0 30px 32px;
    border-radius: 12px;
    line-height: 1.5;
    background: #fff;
    border: 2px solid #fff;
    .hover-img {
      display: none;
      position: absolute;
      top: 2px;
      right: 2px;
      width: 94px;
    }
    .l-title {
      margin: 0 0 6px;
      font-size: 24px;
      font-weight: 700;
      color: #212121;
    }
    .l-desc {
      font-size: 16px;
      color: #595959;
    }
    &:hover {
      background: linear-gradient(
          280deg,
          rgba(69, 95, 255, 0.08) 0%,
          rgba(116, 103, 255, 0) 45.44%
        ),
        #fff;
      box-shadow: 0px 24px 24px 0px rgba(199, 199, 199, 0.08);
      .hover-img {
        display: block;
      }
    }
  }
  ${Media.ipad`
    gap: 15px;
    .line-item {
      width: 400px;
      padding: 22px 0 22px 24px;
      .l-title {
        font-size: 22px;
      }
    }
  `}
  ${Media.phone`
    gap: 0.32rem;
    .line-item {
      width: 6.54rem;
      padding: 0.48rem;
      border-radius: 0.24rem;
      .hover-img {
        width: 1.88rem;
      }
      .l-title {
        margin:  0 0 0.12rem;
        font-size: 0.4rem;
      }
      .l-desc {
        font-size: 0.32rem;
      }
    }
  `}
`

export const BtnsWrap = styled.div`
  padding-bottom: 80px;
  .btn-div {
    justify-content: center;
  }
  ${Media.ipad`
    padding-bottom: 56px;
  `}
  ${Media.phone`
    padding-bottom: 1.12rem;
  `}
`
