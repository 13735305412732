import { Media } from '@raylink/common/theme'
import { Warpper } from '@raylink/common/components'
import styled, { css } from 'styled-components'

export const TableWrap = styled.div`
  position: relative;
`

export const TableHeader = styled.div`
  position: sticky;
  top: ${({ stickyTop }) => `${stickyTop}px`};
  background: #fff;
  box-shadow: ${({ isSticky }) => (isSticky ? '0px 1px 0px 0px #eee' : '')};
  z-index: 1;
`

const fakeDiv = css`
  &::after {
    content: '';
    width: 100%;
    height: 0.44rem;
    position: absolute;
    top: -0.4rem;
    left: 0;
    background: #fff;
  }
`

export const HeaderContent = styled(Warpper)`
  color: #212121;
  font-size: 20px;
  font-weight: 700;
  /* 表格布局，如果只有一列数据，第一列的宽度固定为 336px，其他情况平分宽度 */
  display: grid;
  grid-template-columns: ${({ length }) => {
    return length === 1 ? '336px auto' : `repeat(${length + 1}, 1fr)`
  }};
  margin: auto;
  padding: 20px 0 20px 24px;
  border-radius: 6px;
  background: ${({ isSticky }) => (isSticky ? '#fff' : '#f4f7f9')};
  > div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }
  &::before {
    content: '功能服务';
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  ${({ headerText }) =>
    headerText
      ? `
      &::before {
        content: '功能权益';
      }
      `
      : ''}
  ${Media.ipad`
    padding: 20px 0 20px 30px;
  `}
  ${Media.phone`
    font-size: 0.32rem;
    padding: 0.24rem 0 0.24rem 0.2rem;
    border-radius: 0.09rem;
    position: relative;
    /* 表格布局，如果只有一列数据，表现为两列，其他情况，有几列数据，表现为几列 */
    grid-template-columns: ${({ length }) => {
      return `repeat(${length === 1 ? 2 : length + 1}, 1fr)`
    }};
    > div {
      &:last-child {
        padding-right: ${({ length }) => (length === 1 ? '0.3rem' : '0')};
      }
    }
    &::before {
      /* 如果只有一列数据，需要伪元素占位，凑两列 */
      display: 'block';
      font-size: 0.24rem;
    }
    /* 解决吸顶时rem计算不准确导致的空隙问题 */
    ${({ isSticky }) => (isSticky ? fakeDiv : '')}
  `}
`

export const TableContent = styled(Warpper)``
const TipStyle = css`
  .tip {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      .float {
        top: -10px;
      }
    }
    .float {
      width: 204px;
      font-size: 12px;
      position: absolute;
      top: -9999px;
      left: 50%;
      transform: translate(-50%, -100%);
      transition: all 0s ease 0.1s;
      padding: 10px 12px;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 8px 30px 0px rgba(204, 204, 204, 0.3);
      z-index: 1;
      a {
        color: #11c871;
        font-weight: 500;
      }
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 8px;
        height: 8px;
        transform: translate(-50%, 50%) rotate(45deg);
        background: #fff;
      }
    }
  }
`
export const TableRow = styled.div`
  font-size: 14px;
  color: #878787;
  padding: 13px 0;
  /* 表格布局，如果只有一列数据，第一列的宽度固定为 336px，其他情况平分宽度 */
  display: grid;
  grid-template-columns: ${({ length }) => {
    return length === 1 ? '336px auto' : `repeat(${length + 1}, 1fr)`
  }};
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .name {
    text-align: left;
    padding-left: 24px;
    justify-content: flex-start;
    column-gap: 8px;
    min-height: 21px;
    line-height: 21px;
    img {
      width: 16px;
    }
    ${TipStyle}
  }
  .col {
    color: #464646;
    .col-item {
      display: flex;
      justify-content: center;
      width: 184px;
      min-height: 21px;
      line-height: 21px;
      &:last-child {
        padding-right: ${({ length }) => (length === 1 ? '0.3rem' : '0')};
      }
      ${TipStyle}
      .tip {
        margin-left: 8px;
        img {
          width: 16px;
        }
        .float {
          text-align: left;
        }
      }
    }
  }
  img {
    width: 20px;
    vertical-align: bottom;
  }
  @media (any-hover: hover) {
    &:hover {
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.2);
    }
  }
  ${Media.phone`
    /* 表格布局，如果只有一列数据，表现为一行两列，其他情况，有几列数据，表现为两行几列 */
    grid-template-rows: ${({ length }) => {
      return length === 1 ? 'auto' : '21px auto'
    }};
    font-size: 0.24rem;
    padding: 0.22rem 0;
    .name {
      padding-left: ${({ length }) => (length === 1 ? '0.48rem' : '0.2rem')};
      padding-bottom: 0;
      column-gap: 0.16rem;
      max-width: 1.36rem; // 最长文本长度，防止因为文本过长导致右侧内容出现偏移的问题
      min-height: 0.42rem;
      line-height: 0.42rem;
      & > span {
        white-space: nowrap;
      }
      .tip {
        &:hover {
          .float {
            top: -0.2rem;
          }
        }
        .float {
          width: 4.08rem;
          font-size: 0.24rem;
          padding: 0.2rem 0.24rem;
          border-radius: 0.12rem;
          box-shadow: 0px 0.16rem 0.6rem 0px rgba(204, 204, 204, 0.3);
          &::after {
            width: 0.16rem;
            height: 0.16rem;
          }
        }
      }
    }
    .col {
      .col-item {
        width: auto;
        min-height: 0.42rem;
        line-height: 0.42rem;
        .tip .float {
          width: 2.78rem;
          left: -0.68rem;
        }
      }
      &[data-font-small] {
        .col-item {
          min-height: 0.36rem;
          line-height: 0.36rem;
          font-size: 0.24rem;
        }
      }
    }
    img {
      width: 0.28rem;
    }
  `}
`
