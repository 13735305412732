import React from 'react'
import { useHref } from '@raylink/common/hooks'
import { BannerBtnsWrap, TryBtn, MoreBtn } from './atom'
import { Path } from '@raylink/common/constants/path'

export default function BannerBtns({
  btnInfo = {
    first: {
      text: '立即下载',
      href: Path.download,
    },
    second: {
      text: '了解更多',
      href: Path.price,
    },
  },
  ...rest
}) {
  const paramsHref = useHref()
  return (
    <BannerBtnsWrap {...rest}>
      <TryBtn
        href={paramsHref(btnInfo.first.href)}
        className="banner-btn first"
      >
        {btnInfo.first.text}
      </TryBtn>
      {btnInfo.second && (
        <MoreBtn
          href={paramsHref(btnInfo.second.href)}
          className="banner-btn second"
        >
          {btnInfo.second.text}
        </MoreBtn>
      )}
    </BannerBtnsWrap>
  )
}
