import React from 'react'
import Check from '../../../../../images/product/business/plan/check.svg'
import Arrow from '../../../../../images/product/business/plan/arrow.svg'
import BannerBtns from '../../../../../components/home/bannerBtns'
import { PlanWrap, PlanContent, FeatList, BtnsWrap } from './atom'
import { planList } from '../../config'
import { Path } from '@raylink/common/constants/path'

export function Plan() {
  return (
    <PlanWrap>
      <PlanContent>
        <h2 className="title">RayLink私有化·定制企业专属方案</h2>
        <h2 className="mobile-title">
          <span>RayLink私有化</span>
          <span>定制企业专属方案</span>
        </h2>
        <FeatList>
          {planList.map(item => (
            <div className="item-div">
              <img src={Check} alt="" loading="lazy" />
              {item}
            </div>
          ))}
        </FeatList>
        <BtnsWrap>
          <BannerBtns
            btnInfo={{
              first: {
                text: '立即咨询',
                href: Path.contact,
              },
            }}
            className="btn-div"
          />
          <div className="see-more">
            <a href={Path.private}>查看更多服务详情</a>
            <img src={Arrow} alt="" loading="lazy" />
          </div>
        </BtnsWrap>
      </PlanContent>
    </PlanWrap>
  )
}
