import React, { useState, useEffect } from 'react'
import { Path } from '@raylink/common/constants/path'
import Arrow from '../../../../../images/product/business/arrowRight.svg'
import Check from '../../../../../images/price-business/check-icon.svg'
import { Title } from '../../index.style'
import NotFoldTable from '../../../components/noFoldTable'
import { tableList, KeyEnum } from '../../config'
import { queryBusinessPrice } from '../../../../price/utils/query-business-price'
import { ComparetableWrap, TableTitle, MoreWarp } from './atom'

export function CompareTable() {
  const [priceData, setPriceData] = useState({
    office: '498',
    industry: '998',
  })

  const officeTitle = ({ title, desc, num, href }) => (
    <TableTitle>
      <div className="t-title">{title}</div>
      <div className="t-desc">
        <span>{num}</span>
        {desc}
      </div>
      <a href={href} className="btn">
        立即购买
      </a>
    </TableTitle>
  )

  const titleList = [
    {
      label: officeTitle({
        title: '办公专业版',
        desc: ' / 用户 / 年',
        num: priceData.office,
        href: Path.buyOfficeVip,
      }),
      key: KeyEnum.deploy,
    },
    {
      label: officeTitle({
        title: '行业性能版',
        desc: ' / 用户 / 年',
        num: priceData.industry,
        href: Path.buyIndustryVip,
      }),
      key: KeyEnum.industry,
    },
  ]

  useEffect(() => {
    queryBusinessPrice().then(({ officePrice, industryPrice }) => {
      if (officePrice) {
        setPriceData(pre => ({
          ...pre,
          office: officePrice,
        }))
      }
      if (industryPrice) {
        setPriceData(pre => ({
          ...pre,
          industry: industryPrice,
        }))
      }
    })
  }, [])

  return (
    <ComparetableWrap>
      <Title className="web-item">高性价比之选，灵活选配方案</Title>
      <Title className="phone-item">
        <span>高性价比之选</span>
        <span>灵活选配方案</span>
      </Title>
      <NotFoldTable
        compareList={tableList}
        titleList={titleList}
        checkImg={Check}
        headerText="功能权益"
      />
      <MoreWarp>
        <a className="see-more" href={`${Path.priceBusiness}#compareTable`}>
          了解更多功能与权益
        </a>
        <img src={Arrow} alt="" loading="lazy" />
      </MoreWarp>
    </ComparetableWrap>
  )
}
