import React from 'react'
import Logo from '../../../../../images/product/business/logo.svg'
import BannerBtns from '../../../../../components/home/bannerBtns'
import { OneStopWrap, OneStopContent } from './atom'
import { Path } from '@raylink/common/constants/path'
import { Contact } from '../contact'

export function OneStop() {
  return (
    <OneStopWrap>
      <OneStopContent>
        <img src={Logo} alt="" className="logo" loading="lazy" />
        <h2 className="title">一站式企业远控解决方案</h2>
        <BannerBtns
          btnInfo={{
            first: {
              text: '申领企业版试用',
              href: Path.businessLogin,
            },
            second: {
              text: '立即咨询',
              href: Path.contact,
            },
          }}
          className="btn-div"
        />
        <Contact className="contact-box" />
      </OneStopContent>
    </OneStopWrap>
  )
}
