import { Warpper } from '@raylink/common/components'
import styled from 'styled-components'
import Bg from '../../../../../images/product/business/plan/bg.webp'
import { Media } from '@raylink/common/theme'
import BgIpad from '../../../../../images/product/business/plan/bgIpad.webp'
import BgPhone from '../../../../../images/product/business/plan/bgPhone.webp'

export const PlanWrap = styled.div`
  padding: 80px 0;
  ${Media.ipad`
    padding: 56px 0;
  `}
  ${Media.phone`
    padding: 0.8rem 0;
  `}
`

export const PlanContent = styled(Warpper)`
  padding: 54px 0 58px 70px;
  background: url(${Bg}) no-repeat 0 0 / 100%;
  line-height: 1.5;
  .title {
    font-size: 32px;
    font-weight: 700;
    color: #212121;
    margin: 0;
  }
  .mobile-title {
    display: none;
  }
  ${Media.ipad`
    width: 928px;
    padding: 48px 0 52px 48px;
    background: url(${BgIpad}) no-repeat 0 0 / 100%;
    .title {
      font-size: 30px;
    }
  `}
  ${Media.phone`
    width: 100%;
    padding: 0.64rem 0.64rem 3.04rem;
    background: url(${BgPhone}) no-repeat 0 0 / 100%;
    .title {
      display: none;
    }
    .mobile-title {
      display: block;
      font-size: 0.52rem;
      font-weight: 700;
      color: #212121;
      margin: 0;
      span {
        display: block;
      }
    }
  `}
`

export const FeatList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 32px;
  width: 498px;
  margin: 24px 0 40px;
  .item-div {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    color: #595959;
  }
  ${Media.ipad`
    width: 472px;
    margin: 27px 0 32px;
  `}
  ${Media.phone`
    width: 100%;
    display: grid;
    grid-template-columns: 2.76rem 3.08rem;
    gap: 0.16rem 0.32rem;
    margin: 0.2rem 0 0.48rem;
    .item-div {
      gap: 0.12rem;
      font-size: 0.32rem;
      img {
        width: 0.32rem;
      }
    }
  `}
`

export const BtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  .see-more {
    display: flex;
    align-items: center;
    font-size: 16px;
    a {
      color: #455fff;
      margin-right: 6px;
      transition: all 0.2s;
      &:hover {
        margin-right: 14px;
      }
    }
    img {
      width: 7px;
    }
  }
  ${Media.phone`
    gap: 0.32rem;
    .see-more {
      font-size: 0.32rem;
      a {
        margin-right: 0.12rem;
        &:hover {
          margin-right: 0.28rem;
        }
      }
      img {
        width: 0.11rem;
      }
    }
  `}
`
