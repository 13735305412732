import React from 'react'
import HoverImg from '../../../../../images/product/business/scene/hover.png'
import { Title } from '../../index.style'
import { sceneList } from '../../config'
import { SceneWrap, SceneListWrap, SceneItem } from './atom'

export function Scene() {
  return (
    <SceneWrap>
      <Title>
        <span>满足各类企业远程</span>
        <span>办公场景</span>
      </Title>
      <SceneListWrap>
        {sceneList.map(item => (
          <SceneItem
            key={item.title}
            bg={item.bg}
            mobileBg={item.mobileBg}
            className={item.class}
          >
            <div className={`text-div text-${item.class}`}>
              <h3 className="title">{item.title}</h3>
              <div className="desc">{item.desc}</div>
            </div>
            <img src={HoverImg} alt="" className="hover-img" loading="lazy" />
          </SceneItem>
        ))}
      </SceneListWrap>
    </SceneWrap>
  )
}
