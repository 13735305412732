import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const SceneWrap = styled(Warpper)`
  ${Media.ipad`
    width: 928px;
  `}
  ${Media.phone`
    width: 100%;
  `}
`

export const SceneListWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .one {
    width: 288px;
    height: 338px;
  }
  .two {
    width: 512px;
    height: 338px;
  }
  .third {
    width: 367px;
    height: 202px;
  }
  .four {
    width: 390px;
    height: 211px;
  }
  .five {
    width: 411px;
    height: 211px;
  }
  .six {
    width: 367px;
    height: 347px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  ${Media.ipad`
    gap: 12px;
    .one {
      width: 222px;
      height: 262px;
    }
    .two {
      width: 396px;
      height: 262px;
    }
    .third {
      width: 283px;
      height: 156px;
    }
    .four {
      width: 302px;
      height: 164px;
    }
    .five {
      width: 318px;
      height: 164px;
    }
    .six {
      width: 283px;
      height: 268px;
    }
  `}
  ${Media.phone`
    justify-content: center;
    .six {
      position: relative;
    }
  `}
`

export const SceneItem = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background-size: 100% auto;
  color: #fff;
  line-height: 1.5;
  background: url(${({ bg }) => bg || ''});
  background-size: 100% 100%;
  .text-div {
    position: relative;
    padding: 0 32px;
    z-index: 1;
    transition: margin-top 0.7s;
  }
  .title {
    margin: 0 0 6px;
    font-size: 28px;
    font-weight: 700;
  }
  .desc {
    font-size: 16px;
    opacity: 0;
  }
  .text-one,
  .text-two {
    margin-top: 276px;
  }
  .text-third {
    margin-top: 140px;
  }
  .text-four,
  .text-five {
    margin-top: 149px;
  }
  .text-six {
    margin-top: 285px;
  }
  .hover-img {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 147px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    border-radius: 16px;
    background-size: cover;
  }
  @media (any-hover: hover) {
    &:hover {
      &:after {
        background: linear-gradient(
          107deg,
          rgba(140, 156, 255, 0.88) 0%,
          rgba(69, 95, 255, 0.88) 100%
        );
        backdrop-filter: blur(12px);
      }
      .hover-img {
        display: block;
        z-index: 1;
      }
      .desc {
        opacity: 1;
      }
      .text-one {
        margin-top: 178px;
      }
      .text-two {
        margin-top: 202px;
      }
      .text-third {
        margin-top: 42px;
      }
      .text-four,
      .text-five {
        margin-top: 75px;
      }
      .text-six {
        margin-top: 211px;
      }
    }
  }
  ${Media.ipad`
    .text-div {
      padding: 0 24px;
    }
    .title {
      margin: 0 0 4px;
      font-size: 24px;
    }
    .text-one,
    .text-two {
      margin-top: 213px;
    }
    .text-third {
      margin-top: 112px;
    }
    .text-four,
    .text-five {
      margin-top: 115px;
    }
    .text-six {
      margin-top: 220px;
    }
    .hover-img {
      width: 106px;
    }
    @media (any-hover: hover) {
      &:hover {
        .text-one {
          margin-top: 114px;
        }
        .text-two {
          margin-top: 162px;
        }
        .text-third {
          margin-top: 32px;
        }
        .text-four,
        .text-five {
          margin-top: 40px;
        }
        .text-six {
          margin-top: 144px;
        }
      }
    }
  `}
  ${Media.phone`
    width: 3.32rem !important;
    height: 2.58rem !important;
    border-radius: 0.24rem;
    background: url(${({ mobileBg }) => mobileBg || ''});
    background-size: 100% 100%;
    .text-div {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0;
      padding: 0;
    }
    .desc {
      display: none;
    }
    .title {
      margin: 0 0 0.16rem;
      font-size: 0.4rem;
    }
    .desc {
      font-size: 0.22rem;
    }
    .hover-img {
      width: 1rem;
    }
  `}
`
