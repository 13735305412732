import LocalDeploy from '../../../images/price-business/local-deploy.png'
import Transmission from '../../../images/price-business/transmission.png'
import DataSecurity from '../../../images/price-business/data-security.png'
import PrivateQrCode from '../../../images/price-business/private-qr-code.png'
import { Path } from '@raylink/common/constants/path'

export const BusinessProductId = {
  office: 1, // 办公专业版
  industry: 2, // 行业性能版
}

export const BusinessType = {
  office: BusinessProductId.office,
  industry: BusinessProductId.industry,
  private: 'private',
}

export const businessPriceList = [
  {
    key: BusinessType.office,
    title: '办公专业版',
    text: `适用于团队远程办公、技术支持、\nIT管理等`,
    priceNum: '498',
    btnText: '立即购买',
    href: Path.buyOfficeVip,
    feats: [
      '企业专属线路',
      '支持企业内多人使用（3个用户起）',
      '20余项远控功能',
      '多维度安全管理能力',
      '支持外接设备',
    ],
  },
  {
    key: BusinessType.industry,
    title: '行业性能版',
    text: '适用于企业远程设计、影视后期、\n游戏开发等',
    priceNum: '998',
    btnText: '立即购买',
    href: Path.buyIndustryVip,
    feats: [
      '企业专属线路',
      '支持企业内多人使用（3个用户起）',
      '20余项远控功能',
      '多维度安全管理能力',
      '支持外接设备',
      '支持4K60FPS / 2K144FPS',
      '4 : 4 : 4色彩模式',
    ],
  },
  {
    key: BusinessType.private,
    title: '私有化部署',
    text: '灵活定制企业本地部署方案',
    qRSrc: PrivateQrCode,
    btnText: '立即咨询',
    href: Path.contact,
    feats: [
      '本地服务器部署',
      '定制化开发',
      '针对性解决方案',
      '自主掌控数据',
      '提供专属客户服务',
    ],
  },
]

export const fullItemList = [
  {
    title: '轻量级部署',
    text: '无需复杂配置，安装便捷，支持快速批量部署',
    imgSrc: LocalDeploy,
  },
  {
    title: '平台化管理',
    text: '采用集中式控制台，一站满足权限配置、安全审计等需求',
    imgSrc: Transmission,
  },
  {
    title: '全方位安全保障',
    text: '远控全流程布局安全措施，切实保护企业数据及用户隐私',
    imgSrc: DataSecurity,
  },
]

export const KeyEnum = {
  deploy: 'deploy',
  industry: 'industry',
}

const serviceList = [
  {
    name: '用户数',
    rows: [
      { key: KeyEnum.deploy, value: '1' },
      { key: KeyEnum.industry, value: '1' },
    ],
  },
  {
    name: '受控设备数',
    rows: [
      { key: KeyEnum.deploy, value: '等同于用户数' },
      { key: KeyEnum.industry, value: '等同于用户数' },
    ],
  },
  {
    name: '通道数',
    rows: [
      { key: KeyEnum.deploy, value: '1' },
      { key: KeyEnum.industry, value: '1' },
    ],
  },
]

const deviceList = [
  {
    name: '批量部署设备',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '跨设备系统安装部署',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '被控设备管理',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '被控设备授权管理',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '被控设备组权限策略',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
]

const memberList = [
  {
    name: '自定义组织架构和用户',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '域账号登录配置',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '企业用户管理',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '企业组织管理',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
]

const authorityList = [
  {
    name: '角色权限',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '权限设置',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '安全水印',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '双因子验证',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '审计日志',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
]

const securityList = [
  {
    name: '远程记录',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '登录日志',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '传输记录',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '操作日志',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
]

const connectList = [
  {
    name: '主控设备类型',
    rows: [
      { key: KeyEnum.deploy, value: 'Windows, macOS, Android, iOS, iPadOS' },
      { key: KeyEnum.industry, value: 'Windows, macOS, Android, iOS, iPadOS' },
    ],
  },
  {
    name: '被控设备类型',
    rows: [
      { key: KeyEnum.deploy, value: 'Windows, macOS' },
      { key: KeyEnum.industry, value: 'Windows, macOS' },
    ],
  },
  {
    name: '画质',
    rows: [
      { key: KeyEnum.deploy, value: '最高2K 30FPS' },
      { key: KeyEnum.industry, value: '最高4K 60FPS / 最高2K 144FPS' },
    ],
  },
  {
    name: '色彩模式',
    tip: '4:4:4真彩模式：受控设备需支持NVIDIA独立显卡，驱动版本445.87及以上；主、受控需均为Windows系统',
    rows: [
      { key: KeyEnum.deploy, value: '4 : 2 : 0' },
      { key: KeyEnum.industry, value: '4 : 4 : 4' },
    ],
  },
  {
    name: '文件传输',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '剪切板',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '多屏控多屏',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '防窥模式',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '数位板',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '无人值守访问',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '游戏手柄',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '3D游戏鼠标',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '录屏',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '文字聊天',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '虚拟屏',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '远程开机',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '锁屏、重启、关机、注销',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '发送组合快捷键',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '鼠标锁定',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '多屏预览',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
  {
    name: '声音同步',
    rows: [
      { key: KeyEnum.deploy, value: true },
      { key: KeyEnum.industry, value: true },
    ],
  },
]

export const compareList = [
  {
    type: '服务规格',
    features: serviceList,
  },
  {
    type: '设备管理',
    features: deviceList,
  },
  {
    type: '用户管理',
    features: memberList,
  },
  {
    type: '权限安全与防护',
    features: authorityList,
  },
  {
    type: '安全审计',
    features: securityList,
  },
  {
    type: '远控功能',
    features: connectList,
  },
]

export const questionList = [
  {
    question: '1. 能否免费试用企业版产品？',
    answer:
      '可以，RayLink企业版产品支持7天免费试用。若您需要延长试用时间，请联系我们。',
  },
  {
    question: '2. 已经是办公专业版会员了，是否可以升级到行业性能版会员？',
    answer:
      '可以，需支付一定金额的差价。请进入RayLink管理台>订单管理>我的套餐，点击“升级”按钮，即可查看具体付费金额，支付成功后自动升级至行业性能版会员。',
  },
  {
    question: '3. 已经是行业性能版会员了，是否可以切换到办公专业版会员？',
    answer:
      '在行业性能版会员有效期内，不支持切换至办公专业版会员。您可以在行业性能版会员到期后，再自行购买办公专业版会员。',
  },
]
