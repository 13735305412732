import { Warpper } from '@raylink/common/components'
import styled from 'styled-components'
import Bg from '../../../../../images/product/business/oneStopBg.webp'
import { Media } from '@raylink/common/theme'

export const OneStopWrap = styled.div`
  height: 400px;
  background: url(${Bg}) no-repeat top center / auto 100%;
  ${Media.desktopContact`
    background-size: 100% auto;
  `}
  ${Media.ipad`
    height: 355px;
  `}
  ${Media.phone`
    height: 4.8rem;
  `}
`

export const OneStopContent = styled(Warpper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    width: 188px;
    margin: 90px 0 16px;
  }
  .title {
    font-weight: 700;
    line-height: 1.5;
    font-size: 40px;
    margin: 0;
    color: #212121;
  }
  .btn-div {
    margin: 24px 0 0;
  }
  .contact-box {
    margin-top: 16px;
  }
  ${Media.ipad`
    width: 928px;
    .logo {
      margin: 64px 0 13px;
    }
  `}
  ${Media.phone`
    width: 100%;
    .title {
      font-size: 0.56rem;
    }
    .logo {
      width: 3.26rem;
      margin: 0.8rem 0 0.22rem;
    }
    .btn-div {
      margin: 0.4rem 0 0;
    }
  `}
`
